import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const save = (params) => {
  return ajax.post(`${base}/api/itedu/v1/market-recite`, params);
};

const query = (pageNo) => {
  return ajax.get(`${base}/api/itedu/v1/market-recite/query?pageNo=${pageNo}`);
};

const deleteItem = (id) => {
  return ajax.post(`${base}/api/itedu/v1/market-recite/delete?id=${id}`);
};

const getById = (id) => {
  return ajax.get(`${base}/api/itedu/v1/market-recite?id=${id}`);
};

export const marketReciteApi = {
  save: save,
  query: query,
  deleteItem: deleteItem,
  getById: getById,
};
